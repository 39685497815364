import Header from "../Protected/Header";
import Footer from "../Common/Footer";
import Scripts from "../Common/Scripts/Scripts";
import BlogList from "./BlogList";
import { useEffect, useState } from "react";
import { get } from "../../api";
import './BlogList.css';

function AllBlogs() {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    getAllBlogs();
  }, []);
  const getAllBlogs = async () => {
    try {
      const url = `user/blog`;
      let response = await get(url);
      if (response) {
        setBlogs(response);
      }
    } catch (e) {
      console.error("Error");
    }
  };
  return (
    <div className="page-wrapper">
      <span className="header-span"></span>
      <Scripts />
      <Header />
      <section className="contact-section">
        <div className="auto-container">
          <div className="upper-box">
            <div className="row">
              <BlogList blogs={blogs} />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default AllBlogs;
