import React, { useState } from "react";

// BlogList component to display a list of blogs with pagination and description toggle
const BlogList = ({ blogs }) => {
  const pageSize = 10; // Number of blogs to show per page
  const [visibleBlogs, setVisibleBlogs] = useState(pageSize); // State to track the number of blogs visible
  const [expandedIndex, setExpandedIndex] = useState(null); // State to track the expanded description index

  // Function to handle showing more blogs
  const showMoreBlogs = () => {
    setVisibleBlogs((prev) => prev + pageSize);
  };

  // Function to handle toggling the description view
  const toggleDescription = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div className="blog-list">
      {blogs?.slice(0, visibleBlogs).map((blog, index) => (
        <div key={index} className="blog-item">
          <div className="content-section">
            <a
              target="_blank"
              href={`/#/blogDetail?id=${blog?.id}`}
              className="blog-title"
            >
              {blog?.title}
            </a>
            <p className="blog-description">
              {expandedIndex === index
                ? blog?.description
                : `${blog?.description.slice(0, 50)}...`}
              {/* Show the toggle option only if the description length is greater than 50 */}
              {blog?.description.length > 50 && (
                <span
                  onClick={() => toggleDescription(index)}
                  style={{ cursor: "pointer", color: "blue", marginLeft: "5px" }}
                >
                  {expandedIndex === index ? " Show Less" : " Show More"}
                </span>
              )}
            </p>
            <div className="footer-section">
              <a target="_blank" href={`/#/blogDetail?id=${blog?.id}`}>
                <span className="fa fa-comments"></span>
                <span className="comment-count">{blog?.comments} comments</span>
              </a>
            </div>
          </div>
        </div>
      ))}
      {/* Show More button for pagination */}
      {visibleBlogs < blogs.length && (
        <button onClick={showMoreBlogs} className="show-more-button">
          Show More
        </button>
      )}
    </div>
  );
};

export default BlogList;
