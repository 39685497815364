import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Scripts from "../../Common/Scripts/Scripts";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { InputError } from "../../Common/FormControl/Input";
import { useState } from "react";
import Loading from "../../Common/Loading";
import { post, get } from "../../../api";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { AuthContext } from "../../../context/auth";
import { formatDateHyphen, isDateOld } from "../../../utils/date";
import { scrollTop } from "../../../utils/scroll";
import Success from "../../Common/Alert/Success";
import Error from "../../Common/Alert/Error";
import { weekOptions } from "../../../utils/weeks";
import { jobCategory } from "../../../utils/jobCategory";
import AdminSideBar from "../Dashboard/AdminSideBar";

const PostBlog = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      jobDescription: "",
      roleType: "",
      hours: "",
      rateph: "",
      experience: "",
      qualification: "",
      nvqLevel: "",
      vacancy: "",
      stdate: "",
      enddate: "",
      site: "",
      shift: "",
      choosedays: "",
    },
  });
  const [isLoading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const [blogAddedMsg, setblogAddedMsg] = useState("");
  const [blogAddedErrorMsg, setblogAddedErrorMsg] = useState("");
  const [user, setUser] = useState();
  const { setFavourites } = useContext(AuthContext);
  const [userRoleTypes, setUserTypeRoles] = useState([]);
  const getUserDetail = async () => {
    const url = `/user/users/detail`;
    const response = await get(url);
    setUserDetail(response);
    console.log("profile response", response);
    if (response) {
      setFavourites(response?.favourites?.length);
    }
    setLoading(false);
  };
  const getUserRoleType = async () => {
    const url = `/user/users/type`;
    const response = await get(url);
    if (response) {
      setUserTypeRoles(response);
    }
  };
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    getUserDetail();
    getUserRoleType();
  }, []);
  const submitPostBlog = async (data) => {
    console.log("data", data);
    setLoading(true);
    setblogAddedMsg("");
    const payload = {
      title: data?.title,
      description: data?.description,
    };
    try {
      const url = `/user/blog`;
      const response = await post(url, payload);
      if (response?.status === 201 || response?.status === 200) {
        setblogAddedMsg("success");
        reset({
          title: "",
          description: "",
        });
      } else {
        setblogAddedMsg("error");
      }
      scrollTop();
    } catch (e) {
      console.log("e", e);
      if (e?.response?.data?.message?.includes("Subscription limit")) {
        setblogAddedErrorMsg(
          "You have exhausted your job post limit for the subscription, please apply for enhanced subscription before posting a new job."
        );
      }
      setblogAddedMsg("error");
      scrollTop();
    }

    setLoading(false);
  };
  return (
    <>
      <div className="page-wrapper dashboard ">
        <Scripts />
        <Header />
        <AdminSideBar />

        {/* <!-- Dashboard --> */}
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Post a New Blog!</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title"></div>

                    <div className="widget-content">
                      {/* <FormProvider {...methods}> */}
                      <form
                        className="default-form"
                        onSubmit={handleSubmit(submitPostBlog)}
                      >
                        <div className="row">
                          {/* <!-- Input --> */}
                          {blogAddedMsg === "success" && (
                            <Success
                              msg={`Success: Your blog has been posted. If you want
                            to edit your post, please go to Manage Blogs`}
                            />
                          )}
                          {blogAddedMsg === "error" && (
                            <Error
                              msg={
                                blogAddedErrorMsg
                                  ? blogAddedErrorMsg
                                  : `Error: The blog could not be posted. Please check
                              input and re-submit.`
                              }
                            />
                          )}

                          <div className="form-group col-lg-12 col-md-12">
                            <label>Blog Title</label>
                            <input
                              type="text"
                              name="title"
                              placeholder="Title"
                              {...register("title", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                            />
                            {errors?.title && (
                              <InputError
                                message={errors?.title?.message}
                                key={errors?.title?.message}
                              />
                            )}
                          </div>

                          {/* <!-- About Company --> */}
                          <div className="form-group col-lg-12 col-md-12">
                            <label>Blog Description</label>
                            <textarea
                              {...register("description", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              placeholder="Add description"
                            ></textarea>
                            {errors?.description && (
                              <InputError
                                message={errors?.description?.message}
                                key={errors?.description?.message}
                              />
                            )}
                          </div>
                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-12 col-md-12 text-right pt-4">
                            {isLoading && <Loading />}
                            {!isLoading && (
                              <button
                                type="submit"
                                className="theme-btn btn-style-one"
                              >
                                Post
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                      {/* </FormProvider> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Dashboard --> */}

        {/* <!-- Copyright --> */}
        <Footer />
      </div>
      {/* <!-- End Page Wrapper --> */}
    </>
  );
};
export default PostBlog;
