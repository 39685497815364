import { useRef } from "react";
import "./css/style.css";
import "./css/bootstrap.css";
import { RouterProvider, createHashRouter } from "react-router-dom";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Home from "./components/Home";
import Login from "./components/Login";
import React from "react";
import Register from "./components/Register";
import Dashboard from "./components/Protected/Dashboard/index";
import ValidateUser from "./components/Register/ValidateUser";
import { AuthProvider } from "./context/auth";
import store from "./store/store";
import { Provider } from "react-redux";
import ViewPofile from "./components/Protected/ViewProfile";
import PostJob from "./components/Protected/PostJob/PostJob";
import ManageJobs from "./components/Protected/ManageJobs/ManageJobs";
import Applicants from "./components/Protected/Applicants/Applicants";
import SavedJobs from "./components/Protected/SavedJobs/SavedJobs";
import AppliedJobs from "./components/Protected/AppliedJobs/AppliedJobs";
import Feedback from "./components/Protected/Feedback/Feedback";
import Messages from "./components/Protected/Messages/Messages";
import JobList from "./components/Protected/JobList";
import About from "./components/About";
import Contact from "./components/Contact";
import EmployeeList from "./components/EmployeeList";
import JobSingle from "./components/JobSingle";
import PageNotFound from "./components/PageNotFound";
import ForgotPassword from "./components/Login/ForgotPassword";
import ManageSubscriptions from "./components/Protected/ManageSubscriptions/ManageSubscriptions";
import Timesheet from "./components/Protected/Timesheet/Timesheet";
import ChangePassword from "./components/Login/ChangePassword";
import Meetings from "./components/Protected/Meetings/Meetings";
import CandidateFullProfile from "./components/Protected/CandidateProfile/CandidateFullProfile";
import UserManagement from "./components/Protected/UserManagement/UserManagement";
import Reports from "./components/Protected/Reports/Reports";
import VideoMeeting from "./components/Protected/VideoMeeting/VideoMeeting";
import ManageBlogs from "./components/Protected/ManageBlogs/ManageBlogs";
import BlogDetail from "./components/BlogDetail";
import PostBlog from "./components/Protected/PostBlog/PostBlog";
import AllBlogs from "./components/AllBlogs";

const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/verify-email",
    element: <ValidateUser />,
  },
  {
    path: "/profile",
    element: <ViewPofile />,
  },
  {
    path: "/postjob",
    element: <PostJob />,
  },
  {
    path: "/managejobs",
    element: <ManageJobs />,
  },
  {
    path: "/applicants",
    element: <Applicants />,
  },
  {
    path: "/savedjobs",
    element: <SavedJobs />,
  },
  {
    path: "/appliedjobs",
    element: <AppliedJobs />,
  },
  {
    path: "/feedback",
    element: <Feedback />,
  },
  {
    path: "/messages",
    element: <Messages />,
  },
  {
    path: "/jobList",
    element: <JobList />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/employeeList",
    element: <EmployeeList />,
  },
  {
    path: "/jobSingle",
    element: <JobSingle />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/forgot-password",
    element: <ChangePassword />,
  },
  {
    path: "/managesubscriptions",
    element: <ManageSubscriptions />,
  },
  {
    path: "/timesheet",
    element: <Timesheet />,
  },
  {
    path: "/meetings",
    element: <Meetings />,
  },
  {
    path: "/candidate",
    element: <CandidateFullProfile />,
  },
  {
    path: "/user-management",
    element: <UserManagement />,
  },
  {
    path: "/reports",
    element: <Reports />,
  },
  {
    path: "/video-meeting",
    element: <VideoMeeting />,
  },
  {
    path: "/blogs",
    element: <ManageBlogs />,
  },
  {
    path: "/postBlog",
    element: <PostBlog />,
  },
  {
    path: "/blogDetail",
    element: <BlogDetail />,
  },,
  {
    path: "/allBlogs",
    element: <AllBlogs />,
  },
]);

function App() {
  const tawkMessengerRef = useRef();
  // const handleMinimize = () => {
  //   tawkMessengerRef.current.minimize();
  // };
  return (
    <React.StrictMode>
      <Provider store={store}>
        <AuthProvider>
          <RouterProvider router={router} />
          <TawkMessengerReact
            propertyId="65d285cb8d261e1b5f62212b"
            widgetId="1hmv5l9dq"
            ref={tawkMessengerRef}
          />
        </AuthProvider>
      </Provider>
    </React.StrictMode>
  );
}
export default App;
