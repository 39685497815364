import React, { useState, useEffect } from "react";
import { EMPLOYR_LOGIN_SESSION } from "../../constant/session";
import { getSession } from "../../utils/session";
import { get, put } from "../../api";

// Comments component to display the list of comments
const Comments = ({ comments, blogDetail, getBlogDetail }) => {
  const [user, setUser] = useState(null); // State to track the current user
  const [userDetail, setUserDetail] = useState();
  const [isLogin, setLogin] = useState(false); // State to check if the user is logged in
  const [newComment, setNewComment] = useState(""); // State to track the new comment input
  const [commentsList, setCommentsList] = useState(comments || []); // State to manage the comments list
  const [error, setError] = useState(""); // State to track validation errors

  // Function to check if the user is logged in
  const isUserLogin = async () => {
    const url = `/user/users/isLogin`;
    try {
      const response = await get(url);
      if (response) {
        setLogin(true);
        callMethod();
      }
    } catch (e) {
      setLogin(false);
    }
  };
  const callMethod = async () => {
    try {
      const url = `/user/users/detail`;
      const response = await get(url);
      setUserDetail(response);
    } catch (e) {
      // setLoading(false);
    }
  };

  // Check session and user login status on component mount
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    isUserLogin();
  }, []);

  // Function to validate the comment input
  const validateComment = (comment) => {
    if (!comment.trim()) {
      setError("Comment cannot be empty.");
      return false;
    }
    if (comment.trim().length < 5) {
      setError("Comment must be at least 5 characters long.");
      return false;
    }
    setError("");
    return true;
  };
  useEffect(() => {
    setCommentsList(comments);
  }, [comments])
  // Function to handle comment submission
  const handleCommentSubmit = async () => {
    if (!validateComment(newComment)) return; // Validate before submitting

    // Update comments list
    const updatedComments = [
      ...commentsList,
      {
        comment: newComment,
        userName: userDetail?.firstName,
        blogId: blogDetail?.id,
        createdAt: new Date(),
      },
    ];
    setCommentsList(updatedComments);
    setNewComment(""); // Clear the input field after submitting
    if (blogDetail?.blogComments) {
      blogDetail.blogComments = updatedComments;
    }
    console.log("blogDetail", blogDetail)
    try {
      const url = `/user/blog`;
      const response = await put(url, blogDetail);
      if (
        response?.status === 202 ||
        response?.status === 201 ||
        response?.status === 200
      ) {
      } else {
      }
    } catch (e) {
      console.log("e", e);
    }
    // getBlogDetail()
  };

  return (
    <div className="comments-section" style={styles.commentsSection}>
      <h5 style={styles.commentsHeader}>Comments</h5>
      <ul className="comments-list" style={styles.commentsList}>
        {commentsList.length === 0 ? (
          <p style={styles.noCommentsText}>
            No comments yet. Be the first to comment!
          </p>
        ) : (
          commentsList.map((comment, index) => (
            <li key={index} className="comment-item" style={styles.commentItem}>
              <p>{comment?.comment}</p>
            </li>
          ))
        )}
      </ul>

      {/* Comment input section */}
      <div className="post-comment" style={styles.postComment}>
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Write a comment..."
          disabled={!isLogin}
          className="comment-input"
          rows={3}
          style={{
            ...styles.commentInput,
            ...(isLogin ? {} : styles.disabledInput), // Apply disabled styles if not logged in
          }}
        />
        <button
          onClick={handleCommentSubmit}
          disabled={!isLogin}
          className="submit-comment-button"
          style={{
            ...styles.submitButton,
            ...(isLogin ? {} : styles.disabledButton), // Apply disabled styles if not logged in
          }}
        >
          Post Comment
        </button>
        {!isLogin && (
          <p className="login-warning" style={styles.loginWarning}>
            You must be logged in to post a comment.
          </p>
        )}
        {error && <p style={styles.errorText}>{error}</p>}{" "}
        {/* Display validation errors */}
      </div>
    </div>
  );
};

// Inline styles for the Comments component
const styles = {
  commentsSection: {
    border: "1px solid #ddd",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    marginTop: "20px",
  },
  commentsHeader: {
    marginBottom: "15px",
    fontSize: "18px",
    color: "#333",
  },
  commentsList: {
    listStyle: "none",
    padding: "0",
    marginBottom: "20px",
  },
  commentItem: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    fontSize: "14px",
    color: "#555",
  },
  noCommentsText: {
    fontSize: "14px",
    color: "#888",
  },
  postComment: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  commentInput: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "14px",
    resize: "none",
    outline: "none",
    transition: "border-color 0.3s",
  },
  disabledInput: {
    backgroundColor: "#eee",
    cursor: "not-allowed",
  },
  submitButton: {
    padding: "8px 15px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    fontSize: "14px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  disabledButton: {
    backgroundColor: "#aaa",
    cursor: "not-allowed",
  },
  loginWarning: {
    color: "#d9534f",
    fontSize: "12px",
  },
  errorText: {
    color: "#d9534f",
    fontSize: "12px",
    marginTop: "5px",
  },
};

export default Comments;
