import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Scripts from "../../Common/Scripts/Scripts";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { del, get, put } from "../../../api";
import { InputError } from "../../Common/FormControl/Input";
import { AuthContext } from "../../../context/auth";
import { formatJobDate, isPastdate } from "../../../utils/date";
import { scrollTop } from "../../../utils/scroll";
import Select from "react-select";
import { weekOptions } from "../../../utils/weeks";
import { jobCategory } from "../../../utils/jobCategory";
import AdminSideBar from "../Dashboard/AdminSideBar";

export const sliceArray = (data, startIndex, endIndex) => {
  return data?.slice(startIndex, endIndex);
};

const ManageBlogs = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [userRoleTypes, setUserTypeRoles] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      jobDescription: "",
      roleType: "",
      hours: "",
      rateph: "",
      experience: "",
      qualification: "",
      nvqLevel: "",
      vacancy: "",
      stdate: "",
      enddate: "",
      site: "",
      shift: "",
      choosedays: "",
    },
  });
  const [isLoading, setLoading] = useState(false);
  const [editIdForBlog, setEditIdForBlog] = useState("");
  const [blogs, setBlogs] = useState();
  const [filterBlogs, setFilterBlogs] = useState();
  const [userDetail, setUserDetail] = useState();
  const [jobAddedMsg, setJobAddedMsg] = useState("");
  const fetchAllBlogs = async () => {
    setLoading(true);
    const url = `/user/blog`;
    try {
      let response = await get(url);
      // if (response?.length > 0) {
      //   response = response?.sort(
      //     (objA, objB) => new Date(objA.startDate) - new Date(objB.startDate)
      //   );
      // }

      setBlogs(response);
      if (response?.length > 10) {
        setFilterBlogs(sliceArray(response, 0, 10));
      } else {
        setFilterBlogs(response);
      }

      console.log("blogs response", response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const callMethod = async () => {
    try {
      const url = `/user/users/detail`;
      const response = await get(url);
      setUserDetail(response);
      console.log("profile response", response);
      if (response) {
        fetchAllBlogs();
      }
    } catch (e) {
      //
    }
  };
  const getUserRoleType = async () => {
    try {
      const url = `/user/users/type`;
      const response = await get(url);
      if (response) {
        setUserTypeRoles(response);
      }
    } catch (e) {
      //
    }
  };
  useEffect(() => {
    callMethod();
    getUserRoleType();
  }, []);

  const submitPostBlog = async (data) => {
    // setLoading(true);
    console.log("blog data", data);
    setLoading(true);
    const payload = {
      ...data,
      id: editIdForBlog,
    };
    try {
      const url = `/user/blog`;
      const response = await put(url, payload);
      if (response?.status === 202 || response?.status === 201 || response?.status === 200) {
        console.log("resource updated", response);
        setJobAddedMsg("success");
        setEditIdForBlog(null);
        fetchAllBlogs();
        reset({
          title: '',
          description: '',
          id: '',
          blogComments: '',
        });
      } else {
        setJobAddedMsg("error");
      }
    } catch (e) {
      console.log("e", e);
      setJobAddedMsg("error");
    }

    setLoading(false);
  };

  const editJob = (job) => {
    setJobAddedMsg("");
    setEditIdForBlog(job?.id);
    console.log("job", job);
    reset({
      ...job
    });
  };

  const searchJob = (event) => {
    const value = event?.target?.value;
    if (value) {
      const list = blogs?.filter((x) =>
        x?.title?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilterBlogs(list);
    } else {
      setFilterBlogs(blogs);
    }
  };

  const onDeleteBlog = async (itm) => {
    console.log("onDeleteBlog", itm);
    setLoading(true);
    const url = `/user/blog/${itm?.id}`;
    try {
      const response = await del(url);
      if (response?.status === 200 || response?.status === 204) {
        console.log("response", response);
        fetchAllBlogs();
      } else {
        console.log("something went wrong");
      }
      setLoading(false);
    } catch (e) {
      console.error("e", e);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="page-wrapper dashboard ">
        <Scripts />
        <Header />
        {isLoading && <div className="preloader"></div>}
        <AdminSideBar />
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Manage Blogs</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title">
                      <h4>Blog Listings</h4>
                    </div>

                    <div className="widget-content">
                      <div className="table-outer">
                        <table className="default-table manage-job-table">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Title</th>
                            </tr>
                          </thead>

                          <tbody>
                            {filterBlogs?.length === 0 && (
                              <tr>
                                <td>No Blogs Found !!</td>
                              </tr>
                            )}
                            {filterBlogs?.map((job) => (
                              <tr>
                                <td style={{ padding: "0px" }}>
                                  <div className="option-box">
                                    <ul className="option-list">
                                      <li>
                                        <a
                                          data-text="View"
                                          target="_blank"
                                          href={`/#/blogDetail?id=${job?.id}`}
                                        >
                                          <span className="la la-eye"></span>
                                        </a>
                                      </li>
                                      <li>
                                        <button
                                          data-text="Modify"
                                          onClick={() => {
                                            scrollTop(".update-job-form");
                                            console.log("job ===>", job);
                                            const days = job?.days
                                              ?.split(",")
                                              ?.map((itm) => {
                                                return {
                                                  value: itm,
                                                  label: itm,
                                                };
                                              });
                                            console.log("days", days);
                                            setSelectedOption(days);
                                            editJob(job);
                                          }}
                                        >
                                          <span className="la la-pencil"></span>
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="text-danger"
                                          data-text="Delete"
                                          onClick={() => onDeleteBlog(job)}
                                        >
                                          <span className="la la-trash"></span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td>
                                  <h6>{job?.title}</h6>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="ls-show-more">
                          <p>Showing {filterBlogs?.length} Blogs</p>
                          {blogs?.length > 0 &&
                            blogs?.length !== filterBlogs?.length && (
                              <>
                                <div className="bar">
                                  <span
                                    className="bar-inner"
                                    style={{ width: "40%" }}
                                  ></span>
                                </div>
                                <button
                                  className="show-more"
                                  onClick={() => {
                                    console.log(
                                      "filterBlogs?.length",
                                      filterBlogs
                                    );
                                    const data = sliceArray(
                                      blogs,
                                      filterBlogs?.length,
                                      filterBlogs?.length + 10
                                    );
                                    console.log("data", data);
                                    setFilterBlogs([...filterBlogs, ...data]);
                                  }}
                                >
                                  Show More
                                </button>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title update-job-form">
                      <h4>Update Blog</h4>
                    </div>
                    {jobAddedMsg === "success" && (
                      <div className="message-box success">
                        <p>Success: Your Blog has been updated.</p>
                        <button className="close-btn">
                          <span className="close_icon"></span>
                        </button>
                      </div>
                    )}
                    {jobAddedMsg === "error" && (
                      <div className="message-box error">
                        <p>
                          Error: The Blog could not be updated. Please check
                          input and re-submit.
                        </p>
                        <button className="close-btn">
                          <span className="close_icon"></span>
                        </button>
                      </div>
                    )}

                    <div className="widget-content">
                      <form
                        onSubmit={handleSubmit(submitPostBlog)}
                        className="default-form"
                      >
                        <div className="row">
                          {/* <!-- Input --> */}

                          <div className="form-group col-lg-12 col-md-12">
                            <label>Title</label>
                            <input
                              type="text"
                              name="title"
                              placeholder="Title"
                              {...register("title", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                            />
                            {errors?.title && (
                              <InputError
                                message={errors?.title?.message}
                                key={errors?.title?.message}
                              />
                            )}
                          </div>

                          {/* <!-- About Company --> */}
                          <div className="form-group col-lg-12 col-md-12">
                            <label>Description</label>
                            <textarea
                              {...register("description", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              placeholder=""
                            ></textarea>
                            {errors?.description && (
                              <InputError
                                message={errors?.description?.message}
                                key={errors?.description?.message}
                              />
                            )}
                          </div>
                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-12 col-md-12 text-right">
                            <button
                              type="submit"
                              className="theme-btn btn-style-one"
                            >
                              Update Blog
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
      {/* <!-- End Dashboard --> */}
    </>
  );
};
export default ManageBlogs;
