import Header from "../Protected/Header";
import Footer from "../Common/Footer";
import Scripts from "../Common/Scripts/Scripts";
import one from "../../images/background/1.jpg";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { get } from "../../api";
import Comments from "./Comments";

function BlogDetail() {
  const [searchParams] = useSearchParams();
  const [blogDetail, setBlogDetail] = useState({});
  const blogId = searchParams.get("id");
  useEffect(() => {
    getBlogDetail();
  }, []);
  const getBlogDetail = async () => {
    try {
      const url = `user/blog/${blogId}`;
      let response = await get(url);
      if (response) {
        setBlogDetail(response);
      }
    } catch (e) {
      console.error("Error");
    }
  };
  return (
    <div className="page-wrapper">
      <Scripts />
      <span className="header-span"></span>
      <Header />
      {/* <!--Page Title--> */}
      <section className="page-title">
        <div className="auto-container">
          <div className="title-outer">
            <h1>{blogDetail?.title}</h1>
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- About Section Three --> */}
      <section className="about-section-three blog-section">
        <div className="auto-container">
          {/* <!-- Fun Fact Section --> */}

          <div className="text-box">
            <h4>{blogDetail?.title}</h4>
            <p className="border-bottom pb-4">{blogDetail?.description}</p>
            <p className="mt-2">
              <Comments
                comments={blogDetail?.blogComments || []}
                blogDetail={blogDetail}
                getBlogDetail={getBlogDetail}
              />
            </p>
          </div>
        </div>
      </section>
      {/* <!-- End About Section Three --> */}

      <section
        className="call-to-action-two"
        style={{ backgroundImage: `url(${one})` }}
      >
        <div className="auto-container">
          <div className="sec-title light text-center">
            <h2>Lets get going!</h2>
          </div>

          <div className="btn-box">
            <a
              href="/#/jobList"
              target="_blank"
              className="theme-btn btn-style-three"
            >
              Search Job
            </a>
          </div>
        </div>
      </section>
      {/* <!-- End Call To Action --> */}
      <Footer />
    </div>
  );
}

export default BlogDetail;
