import { useLocation, useNavigate } from "react-router-dom";
import { clearSession, getSession } from "../../../utils/session";
import { post } from "../../../api";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
const AdminSideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logout = async (e) => {
    e?.preventDefault();
    try {
      const url = "user/users/logout";
      const response = await post(url, {});
      if (response?.status === 200) {
        clearSession(EMPLOYR_LOGIN_SESSION);
        navigate("/login");
      }
    } catch (e) {
      if (e?.response?.data?.status === 498) {
        clearSession(EMPLOYR_LOGIN_SESSION);
        navigate("/login");
      }
    }
  };
  const isActiveNav = (nav) => {
    return location.pathname.indexOf(nav) !== -1 ? "active" : "";
  };
  return (
    <>
      {/* <!-- User Sidebar --> */}
      <div className="user-sidebar">
        <div className="sidebar-inner">
          <ul className="navigation">
            <li className={isActiveNav("dashboard")}>
              <a href="/#/dashboard">
                {" "}
                <i className="la la-home"></i> Dashboard
              </a>
            </li>
            <li className={isActiveNav("profile")}>
              <a href="/#/profile">
                <i className="la la-user-tie"></i>View Profile
              </a>
            </li>
            <li className={isActiveNav("reports")}>
              <a href="/#/reports">
                <i className="la la-chart-pie"></i>Reports
              </a>
            </li>
            <li className={isActiveNav("managesubscriptions")}>
              <a href="/#/managesubscriptions">
                <i className="la la-user-edit"></i> Manage Subscriptions
              </a>
            </li>
            <li className={isActiveNav("blogs")}>
              <a href="/#/blogs">
                <i className="la la-list"></i> Manage Blogs
              </a>
            </li>
            <li className={isActiveNav("postBlog")}>
              <a href="/#/postBlog">
                <i className="la la-edit"></i> Post New Blog
              </a>
            </li>
            <li className={isActiveNav("messages")}>
              <a href="/#/messages">
                <i className="la la-envelope"></i>Messages
              </a>
            </li>
            <li className={isActiveNav("feedback")}>
              <a href="/#/feedback">
                <i className="la la-sms"></i> Feedback
              </a>
            </li>
            <li className={isActiveNav("user-management")}>
              <a href="/#/user-management">
                <i className="la la-user-edit"></i> User Management
              </a>
            </li>
            <li>
              <a onClick={logout}>
                <i className="la la-sign-out"></i>Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- End User Sidebar --> */}
    </>
  );
};
export default AdminSideBar;
